window.loginPagesHelper = {

    // add error message attributes
    addErrorMessageAttributes: function(fieldId, errorId) {
        var errorsDiv = document.querySelector('div.validation-summary-errors');
        if (!errorsDiv) {
            return;
        }
        var errorItems = errorsDiv.querySelectorAll('li');
        if (!errorItems) {
            return;
        }
        var field = document.getElementById(fieldId);
        if (!field) {
            return;
        }

        var describedByAttrName = 'aria-describedby';
        var describedByAttrVal = field.getAttribute(describedByAttrName) || '';
        var describedByAttrArr = [];
        for (var i = 0; i < errorItems.length; i++) {
            let itemDisplay = window.getComputedStyle(errorItems[i]).getPropertyValue('display');
            if (itemDisplay === 'none') {
                continue;
            }
            var errorItemId = errorId + '_' + i;
            errorItems[i].id = errorItemId;
            errorItems[i].setAttribute('role', 'alert');
            describedByAttrArr.push(errorItemId);
        }
        if (!describedByAttrArr.length) {
            return;
        }

        var describedByAttrNewVal = describedByAttrArr.join(' ');
        if (describedByAttrVal) {
            describedByAttrNewVal = ' ' + describedByAttrNewVal;
        }
        describedByAttrNewVal = describedByAttrVal + describedByAttrNewVal;
        field.setAttribute(describedByAttrName, describedByAttrNewVal);
    } // add error message attributes
};
